.survey {
  position: relative;
  padding: 3.5em 0;
  background: $white;
  color: $dark-grey;

  @include media-tablet-h {
    height: 31em;
    padding: 0; }

  @include media-desktop {
    height: 32em; }

  @include media-desktop-1440 {
    height: 34em; }

  &__cont {
    display: flex;
    align-items: center;
    height: 100%; }

  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    max-width: 37.5em;

    @include media-tablet-h {
      row-gap: 2.5em;
      max-width: 36.0625em;
      width: 48%; }

    @include media-desktop {
      row-gap: 3.6875em; }

    @include media-desktop-1440 {
      width: 45%; } }

  &__title {
    @include h36;

    @include media-tablet {
      @include h48; } }

  &__descr {
    @include fs14;
    display: flex;
    flex-direction: column;

    @include media-tablet {
      @include fs24; }

    @include media-tablet-h {
      @include fs20; }

    @include media-desktop-1440 {
      @include fs24; }

    p {
      @include reset; } }

  &__footer {
    display: flex;
    flex-direction: column;
    row-gap: 1.25em; }

  &__disclaimer {
    @include fs14;
    color: $grey-88; }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 48%;
    height: 31em;
    border-radius: 1.25em 0 0 1.25em;
    overflow: hidden;

    @include media-tablet-h {
      display: block; }

    @include media-desktop {
      width: 43%;
      height: 32em; }

    @include media-desktop-1440 {
      width: 49%;
      height: 34em; }

    img {
      @include cover-img; } } }
