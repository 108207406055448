.price {
  padding: 3.5em 0;
  background: $grey-f0;
  color: $dark-grey;

  @include media-tablet-h {
    padding: 5em 0; }

  &__cont {
    display: flex;
    flex-direction: column;
    row-gap: 3.75em;

    @include media-desktop {
      row-gap: 5em; } }

  &__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6.25em; }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2em;

    @include media-tablet-h {
      flex-direction: row;
      column-gap: 4.5em; } }

  &__title {
    @include reset;
    @include h36;

    @include media-tablet {
      @include h48; } }

  &__desc {
    @include p14;
    display: flex;
    flex-direction: column;
    row-gap: 0.875rem;

    @include media-tablet-h {
      @include p16;
      flex: 0 0 auto;
      width: calc(50% - 1rem); }

    p {
      @include reset; }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none; } }

    ol {
      margin: 0; } }

  &__link {
    font-size: 0.875rem;
    line-height: 1.55;
    font-weight: 600;

    @include media-tablet-h {
      font-size: 1rem; }

    &:focus {
      outline: none; } }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2em;

    @include media-tablet-h {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center; }

    &--archive {
      gap: 3.5em;
      margin-top: -1.75em;

      @include media-tablet-h {
        flex-direction: column; }

      @include media-desktop {
        margin-top: 0; }

      @include media-desktop-1440 {
        display: grid;
        grid-template-columns: 35.5rem 1fr;
        gap: 2em; }

      @include media-desktop-1600 {
        grid-template-columns: 1fr 1fr; }

      .price__desc {
        @include media-tablet {
          @include p16; }

        @include media-tablet-h {
          width: 49.875rem; }

        @include media-desktop {
          width: 100%;
          font-size: 1.3125em; }

        @include media-desktop-1600 {
          @include p24; } }

      .price__link {
        @include media-tablet {
          font-size: 1rem; }

        @include media-desktop {
          font-size: 1.125rem; } }

      .price__item {
        @include media-tablet-h {
          width: 100%; }

        @include media-desktop-1440; } } }

  &__item {
    @include media-tablet-h {
      width: calc( 50% - 1em ); }

    &-btn {
      width: 100%;
      max-width: 28.25em;
      margin: auto auto 0;
      text-align: center; }

    &--base {
      .tariff__description {
        .tariff__features {
          li:nth-child(2) {
            &::after {
              content: "*";
              margin-left: -0.625rem; } } } } }

    &--full {
      .tariff__description {
        .tariff__features {
          li:nth-child(4) {
            &::after {
              content: "*";
              margin-left: -0.625rem; } } } } }

    &--archive {}

    &--hide {
      display: none; } }

  &__footnote {
    @include p14;
    display: flex;
    flex-direction: column;
    row-gap: 0.875rem;

    @include media-tablet-h {
      @include p16; }

    p {
      @include reset; } } }
