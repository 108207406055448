.reviews {
  position: relative;
  padding: 3.5em 0;
  background: $grey-f0;

  @include media-tablet-h {
    padding: 5em 0; }

  @include media-desktop-1920 {
    padding-bottom: 9.875em; }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 43.0625em;
    border-top-right-radius: 13.25em;
    background: $red;
    z-index: 1;

    @include media-tablet-h {
      height: 22.5em; } }

  &__cont {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;
    z-index: 2;

    @include media-tablet {
      row-gap: 3.5em; }

    @include media-desktop {
      row-gap: 5em; } }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2em;

    @include media-tablet-h {
      flex-direction: row;
      column-gap: 4.5em; } }

  &__title {
    @include h36;

    @include media-tablet {
      @include h48; } }

  &__desc {
    @include p14;

    @include media-tablet {
      @include p18; }

    @include media-tablet-h {
      @include p16;
      flex: 0 0 auto;
      width: calc(50% - 1rem); } }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2em;

    @include media-tablet-h {
      flex-direction: row; } }

  &__item {
    @include media-tablet-h {
      width: calc( 50% - 1em ); } } }
