.slider {
  padding: 2.5em 0 5.125em;
  background: $dark-grey;

  @include media-tablet {
    padding-bottom: 5.75em; }

  @include media-tablet-h {
    padding: 3.75em 0 6.75em; }

  @include media-desktop {
    padding: 5em 0 8.25em; }

  &__wrapper {
    position: relative;

    @include media-desktop {
      margin: 0; } }

  &__item {
    position: relative;
    height: 44em;

    @include media-tablet-x {
      height: 38em; }

    @include media-tablet {
      height: 42em; }

    @include media-tablet-h {
      display: flex;
      height: 42em; }

    @include media-desktop {
      height: 48em; }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2em;
      height: 100%;
      padding: 2em 1.25em;
      border-radius: 1.25em;
      background: $white;
      z-index: 10;

      @include media-tablet-x {
        padding: 2.5em 1.5em; }

      @include media-tablet {
        padding: 2.5em; }

      @include media-tablet-h {
        padding: 2.5em 5.5em; }

      @include media-desktop {
        gap: 2.5em;
        flex: 1 1 auto;
        margin-right: -1.25em;
        padding: 3.375em 2em 3.375em 3.5em; }

      @include media-desktop-1440 {
        padding-right: 2.5em;
        padding-left: 4.5em; }

      h2 {
        @include h20;
        color: $red;

        @include media-tablet-x {
          @include h24; }

        @include media-tablet {
          @include h32; } }

      & > ul {
        @include lreset;
        @include fs11;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        @include media-tablet-x {
          @include fs14; }

        @include media-tablet {
          @include fs18; }

        @include media-desktop-1600 {
          @include fs20; }

        & > li {
          padding-left: 1.5rem;
          background: url("@public/images/icon-check.svg") 0 0 / 1rem 1.1rem no-repeat;

          @include media-tablet {
            padding-left: 1.875rem;
            background: url("@public/images/icon-check.svg") 0 0.15rem / 1.25rem 1.3125rem no-repeat; }

          @include media-desktop-1440 {
            background-position-y: 0.25rem; } }

        ul {
          padding-left: 1.25rem;
          margin-top: 0.625rem; } } }

    &-img {
      display: none;

      @include media-desktop {
        flex: 0 0 31em;
        display: block;
        height: 100%;
        border-radius: 0 1.25em 1.25em 0;
        overflow: hidden;
        z-index: 1; }

      @include media-desktop-1440 {
        flex-basis: 34em; }

      @include media-desktop-1600 {
        flex-basis: 36em; }

      img {
        @include cover-img; } } }

  .swiper-container {
    overflow: visible;

    .swiper-pagination-bullets {
      bottom: -2.125rem;
      display: flex;
      justify-content: center;
      gap: 0.75rem;

      @include media-tablet {
        bottom: -3.25rem; }

      .swiper-pagination-bullet {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0;
        background: $grey-c0;
        opacity: 1;

        @include media-tablet {
          width: 0.75rem;
          height: 0.75rem; }

        &-active {
          background: $red; } } } }

  &__nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 50;
    display: none;
    width: 2.5em;
    height: 2.9375em;
    background: url(@public/images/icon-arrow2.svg) center / cover no-repeat;
    cursor: pointer;
    transition: opacity 0.2s linear;

    @include media-desktop {
      display: block; }

    @include media-desktop-1440 {
      width: 2.9375em;
      height: 3.5em; }

    &:hover {
      opacity: 0.8; }

    &--prev {
      left: 0; }

    &--next {
      right: 0;
      transform: translateY(-50%) rotate(180deg); } } }
