.review {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.5em;
  padding: 1.5em;
  border-radius: 0.75em;
  background: $white;

  &__user {
    display: flex;
    flex-direction: column;
    row-gap: 1.25em;
    width: 100%;

    @include media-tablet {
      flex-direction: row;
      align-items: center;
      column-gap: 2em;
      width: auto; }

    &-photo {
      flex: 0 0 6.8125em;
      width: 6.8125em;
      height: 6.8125em;
      border-radius: 50%;
      margin: 0 auto;
      overflow: hidden;

      img {
        @include cover-img; } }

    &-info {
      display: flex;
      flex-direction: column;
      row-gap: 0.5em; }

    &-name {
      @include h20;

      @include media-tablet {
        @include h24; } }

    &-position {
      @include fs18;

      @include media-tablet {
        @include fs20; } } }

  &__text {
    @include fs14;

    @include media-tablet {
      @include fs18; } } }
