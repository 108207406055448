$mobile-padding: 1.25em;
$tablet-padding: 1.875em;
$desktop-padding: 5em;

$mobile-width: 20em;
$tablet-width-x: 36em;
$tablet-width: 48em;
$tablet-width-h: 64em;
$desktop-width: 80em;
$desktop-width-1440: 90em;
$desktop-width-1600: 100em;
$desktop-width-1920: 120em;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
