.rating-maintenance {
  padding: 3.5em 0;

  @include media-tablet-h {
    padding-top: 5em;
    padding-bottom: 5em; }

  &__cont {
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;

    @include media-tablet-h {
      row-gap: 4em; }

    p {
      @include reset;
      @include h24;

      @include media-tablet {
        @include h32; }

      @include media-tablet-h {
        @include h48; } }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none; } } } }
