.tariff {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1.5em 1.5em 2em 1.5em;
  border-radius: 0.75em;
  background: $white;

  @include media-tablet {
    gap: 2.5em;
    padding: 2.5em; }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.875em;

    @include media-tablet {
      gap: 0.875em; } }

  &__title {
    @include h28;

    @include media-tablet {
      @include h36; } }

  &__subtitle {
    @include reset;
    @include fs20;
    font-weight: 600;

    @include media-tablet {
      @include fs24; } }

  &__promo {
    @include fs18;
    font-weight: 600; }

  &__price {
    @include h28;
    display: flex;
    flex-direction: column;
    font-weight: 700;

    @include media-tablet {
      @include h32;
      flex-direction: row;
      align-items: center;
      column-gap: 0.625rem;
      font-weight: 700; }

    &-note {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25;

      @include media-tablet {
        font-size: 1.125rem; } } }

  &__new-price {
    @include fs14;
    text-decoration: line-through;
    color: $grey-88;

    @include media-tablet {
      @include fs18; } }

  &__features {
    @include lreset;
    @include fs14;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-weight: 600;

    @include media-tablet {
      @include fs16;
      gap: 1.125rem; }

    @include media-desktop-1440 {
      @include fs18; }

    &-item {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;

      &::before {
        content: '';
        flex: 0 0 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        background: url("../images/icon-check.svg") 50% / contain no-repeat; }

      &--plus {
        &::before {
          background-image: url("../images/icon-plus.svg"); } }

      &--minus {
        &::before {
          background-image: url("../images/icon-minus.svg"); } } }

    &-subitem {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
      margin-top: -0.375rem;
      padding-left: 1.875rem;
      font-size: 0.875rem;
      font-weight: 400;

      @include media-desktop-1440 {
        margin-top: -0.625rem;
        font-size: 1rem; }

      &::before {
        content: '';
        flex: 0 0 0.25rem;
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 50%;
        background: $dark-grey; }

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none; } } } }

  &--inner-page {
    gap: 2em;

    .tariff__header {
      gap: 0.625em; }

    .tariff__title,
    .tariff__price {
      @include h24;

      @include media-tablet {
        @include h28; } }

    .tariff__features {
      @include media-tablet {
        @include fs14; } } } }
