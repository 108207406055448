.points {
  display: flex;
  flex-direction: column;
  gap: 1.125em;

  &__title {
    @include fs18;
    font-weight: 700; }

  &__steps {
    display: flex;
    flex-direction: column;
    gap: 2em; }

  &__step {
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    padding: 1.25em;
    border-radius: 1.25em;
    border: 1px solid $grey-e5;

    @include media-tablet-x {
      gap: 1.5em;
      padding: 1.5em; }

    &-header {
      display: flex;
      align-items: center;
      column-gap: 0.875em; }

    &-number {
      @include h16;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 2.125rem;
      width: 2.125rem;
      height: 2.125rem;
      padding: 0.5rem;
      border-radius: 50%;
      background: $red;
      color: $white;
      text-align: center;

      @include media-tablet-x {
        @include h18;
        flex-basis: 2.4375rem;
        width: 2.4375rem;
        height: 2.4375rem; } }

    &-title {
      @include h16; }

    &-description {
      @include p14;
      display: flex;
      flex-direction: column;
      gap: 0.75rem; }

    ol {
      margin: 0;
      padding-left: 1.5rem; }

    p {
      margin: 0; }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none; } } } }
