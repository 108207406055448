.netw-resultcard {
  position: relative;
  display: flex;
  border-radius: 1.25rem;
  background-color: $white;
  overflow: hidden;
  align-items: center;
  margin-top: 32px;
  width: 100%;
  font-size: 14px;
  line-height: 1.3;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;

  @include media-tablet {
    align-items: center;
    justify-content: space-between;
    padding: 40px 44px;
    flex-direction: row; }

  &-info {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;

    @include media-tablet {
      align-items: center;
      width: 35%;
      flex-direction: row; } }

  &__bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; } }

  &__logo {
    display: block;
    width: 5rem;
    height: 5rem;
    padding: 0.875rem 0.5625rem;
    border-radius: 0.25rem;
    border: 1px solid $grey-e5;
    background-color: $white;
    flex-shrink: 0;
    margin-bottom: 1em;

    @include media-tablet {
      margin-right: 1em;
      margin-bottom: 0em; }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center; } }

  &__title {
    @include reset;
    @include fs18;
    font-weight: 800;

    &:hover {
      color: $color-red;
      text-decoration: underline; } }

  &__desc {
    @include reset;
    @include p11;
    padding-top: 0.1rem;
    margin-bottom: 2em;

    @include media-tablet {
      padding-top: 0.5rem;
      margin-bottom: 0; } }

  &__more-link {
    @include fs11;
    color: $color-red;
    text-decoration: underline; }

  &__contacts {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex-shrink: 0;
    justify-content: flex-end;
    margin-bottom: 1.2em;

    @include media-tablet {
      margin-bottom: 0;
      width: 30%; }

    &-title {
      @include reset;
      @include fs14;
      font-weight: bold;
      color: $dark-grey;
      margin-left: 0.5em; }

    &-list {
      @include lreset;
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.5rem;
      align-items: center; }

    &-item {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
      overflow: hidden;
      background-color: $grey-c0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }

      &--counter {
        @include h24;
        width: auto;
        height: auto;
        color: $grey-c0;
        background-color: transparent; } } }

  &__buttons {
    display: grid;
    row-gap: 0.5rem;
    flex-shrink: 0;
    width: 100%;
    border-top: $grey-e5 1px solid;
    padding-top: 1.5em;

    @include media-tablet {
      padding-top: 0;
      padding-left: 1.5em;
      border-left: $grey-e5 1px solid;
      width: initial;
      border-top: none; }

    .btn {
      min-height: 2.625rem; } }

  &--agent {
    .netw-card {
      &__title {
        text-decoration: underline;

        &:hover {
          color: $color-red;
          text-decoration: none; } }

      &__logo {
        padding: 0;
        width: 5rem;
        height: 5rem;
        border: none;
        border-radius: 50%;
        overflow: hidden;

        img {
          object-fit: cover; } }

      &__title {
        @include fs16; }

      &__desc {
        @include fs11; }

      &__contacts {
        display: flex;
        align-items: center;

        &-item {
          width: 4.25rem;
          height: 2.5rem;
          padding: 0.3125rem 0.375rem;
          border-radius: 0;
          background: $white;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center; } } } } } }
