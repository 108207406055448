.cssload-container {
  display: block;
  width: 97px;
  margin: auto; }

.cssload-loading {
  i {
    display: inline-block;
    width: 49px;
    height: 49px;
    border-radius: 50%;
    background: $color-black;

    &:nth-child(1) {
      animation: cssload-loading-ani1 1.15s ease-in-out infinite; }

    &:nth-child(2) {
      background: $red;
      margin-left: -10px;
      animation: cssload-loading-ani1 1.15s ease-in-out 0.58s infinite; } } }

@keyframes cssload-loading-ani1 {
  70% {
    transform: scale(0.5); } }

.preloader-component {
  display: flex;
  column-gap: 10px;

  &__item {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $white;
    animation-name: bounce_circleG;
    -o-animation-name: bounce_circleG;
    -ms-animation-name: bounce_circleG;
    -webkit-animation-name: bounce_circleG;
    -moz-animation-name: bounce_circleG;
    animation-duration: 2.24s;
    -o-animation-duration: 2.24s;
    -ms-animation-duration: 2.24s;
    -webkit-animation-duration: 2.24s;
    -moz-animation-duration: 2.24s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;

    @include media-tablet {
      width: 20px;
      height: 20px; }

    &--1 {
      animation-delay: 0.45s;
      -o-animation-delay: 0.45s;
      -ms-animation-delay: 0.45s;
      -webkit-animation-delay: 0.45s;
      -moz-animation-delay: 0.45s; }

    &--2 {
      animation-delay: 1.05s;
      -o-animation-delay: 1.05s;
      -ms-animation-delay: 1.05s;
      -webkit-animation-delay: 1.05s;
      -moz-animation-delay: 1.05s; }

    &--3 {
      animation-delay: 1.35s;
      -o-animation-delay: 1.35s;
      -ms-animation-delay: 1.35s;
      -webkit-animation-delay: 1.35s;
      -moz-animation-delay: 1.35s; } } }

@keyframes bounce_circleG {
  0% {}

  50% {
    background-color: $red; }

  100% {} }

@-o-keyframes bounce_circleG {
  0% {}

  50% {
    background-color: $red; }

  100% {} }

@-ms-keyframes bounce_circleG {
  0% {}

  50% {
    background-color: $red; }

  100% {} }

@-webkit-keyframes bounce_circleG {
  0% {}

  50% {
    background-color: $red; }

  100% {} }

@-moz-keyframes bounce_circleG {
  0% {}

  50% {
    background-color: $red; }

  100% {} }
