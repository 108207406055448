.arab-east {
  padding: 3.5em 0;
  background: $grey-f0;

  @include media-tablet-h {
    padding: 2.5em 0; }

  &__main {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 2.5em;

    @include media-tablet-h {
      flex-direction: row;
      align-items: center;
      column-gap: 3.0625em; }

    @include media-desktop {
      column-gap: 4.25em; }

    @include media-desktop-1440 {
      column-gap: 6.5em; }

    @include media-desktop-1600 {
      height: 36.125em; } }

  &__left {
    @include media-tablet-h {
      flex: 0 0 29.5em;
      padding-right: 0; }

    @include media-desktop {
      flex-basis: 32.625em; }

    @include media-desktop-1600 {
      flex-basis: auto;
      z-index: 1; } }

  &__title {
    @include h36;

    @include media-tablet {
      @include h48; }

    @include media-desktop-1600 {
      width: 27.625rem; }

    span {
      color: $red; } }

  &__right {
    padding-left: 1.25em;
    padding-right: 1.25em;

    @include media-tablet {
      padding-left: 1.875em;
      padding-right: 1.875em; }

    @include media-tablet-h {
      padding-left: 0;
      padding-right: 0; }

    @include media-desktop-1600 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 50%;
      margin-right: 0;
      z-index: 2; } }

  &__list {
    @include lreset;
    display: flex;
    flex-direction: column;
    row-gap: 1.125em; }

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 0.625em;
    padding: 1em;
    border-radius: 1.5em;
    background: $white;

    @include media-tablet {
      flex-direction: row;
      column-gap: 3.3125em;
      align-items: center;
      height: 6.9375em;
      padding: 1.5em;
      border-radius: 4.375em; }

    @include media-tablet-h {
      column-gap: 1.5em;
      padding: 1em 1.875em 1em 2.5em;
      border-radius: 4.375em 0 0 4.375em; }

    @include media-desktop {
      column-gap: 2.5em;
      padding-right: 5em; }

    &-title {
      @include h20;
      color: $red;

      @include media-tablet {
        @include h22;
        flex: 0 0 15.25rem; }

      @include media-tablet-h {
        flex-basis: 10.1875rem; }

      @include media-desktop {
        @include h24; }

      @include media-desktop-1440 {
        @include h32;
        flex-basis: 13.625rem; } }

    &-desc {
      @include fs14;

      @include media-tablet {
        @include fs16; }

      @include media-tablet-h {
        @include fs14; }

      @include media-desktop {
        @include fs16; }

      @include media-desktop-1440 {
        @include fs18; }

      @include media-desktop-1600 {
        flex-basis: 25.25rem; } } } }
