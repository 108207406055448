.payment {
  width: 100%;
  display: flex;
  justify-content: center;
  background: $grey-f5;
  color: $dark-grey;

  @include media-desktop {
    min-height: 54.375em;
    align-items: center; }

  &__wrapper {
    max-width: 41em;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    margin: 4em 0; }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 0.75em;
    padding: 0 1.25em;

    @include media-tablet {
      padding: 0; } }

  &__title {
    @include h24;
    margin: 0;

    @include media-tablet-x {
      @include h28; } }

  &__description {
    @include fs14;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    @include media-tablet-x {
      @include fs16; }

    p {
      @include reset; } }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5em;
    padding: 1.25em;
    border-radius: 1.25em;
    background: $white;

    @include media-tablet-x {
      gap: 2em;
      padding: 2.5em; }

    &-header {
      display: flex;
      align-items: center;
      column-gap: 1.25em; }

    &-number {
      @include h16;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 2.125rem;
      width: 2.125rem;
      height: 2.125rem;
      padding: 0.5rem;
      border-radius: 50%;
      background: $grey-d5;
      color: $grey-88;

      @include media-tablet-x {
        @include h18;
        flex-basis: 2.4375rem;
        width: 2.4375rem;
        height: 2.4375rem; } }

    &-title {
      @include h16;

      @include media-tablet-x {
        @include h18; } }

    &-body {
      display: flex;
      flex-direction: column;
      gap: 2em; }

    &-description {
      @include fs16;
      display: flex;
      flex-direction: column;
      row-gap: 0.75rem;

      p {
        @include reset; } }

    &-btn {
      text-align: center; } }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2em; }

  &__radio {
    &-label {
      cursor: pointer; }

    input:checked + .payment__radio-label {
      .payment__tariff {
        &::before {
          background-image: url("@public/images/tariffs/radio-active.svg"); } } }

    &--no-tariff {
      .payment__radio-label {
        display: flex;
        align-items: center;
        column-gap: 10px;
        color: #939999;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.16px;
        cursor: pointer;

        &::before {
          content: "";
          flex: 0 0 20px;
          width: 20px;
          height: 20px;
          background: url("@public/images/tariffs/radio.svg") no-repeat;
          transition: all ease 0.3s; } }

      input:checked + .payment__radio-label {
        &::before {
          background-image: url("@public/images/tariffs/radio-active.svg"); } } }

    &--active {
      .payment__tariff {
        border-color: $red; } }

    &--hide {
      display: none; } }

  &__input[type="text"] {
    @include fs18;
    height: 2.8125rem;
    padding: 0 0.875rem;
    border: 1px solid $color-black;
    border-radius: 0.25rem; }

  &__tariff {
    position: relative;
    padding: 1.25em 1.25em 1.5em 3.25em;
    border-radius: 0.75em;
    border: 2px solid $grey-c0;

    @include media-tablet-x {
      padding: 1.5em 1.5em 2em 3.625em; }

    &::before {
      content: '';
      position: absolute;
      top: 1.5625em;
      left: 1.125em;
      width: 1.25em;
      height: 1.25em;
      background: url("@public/images/tariffs/radio.svg") no-repeat;
      transition: all ease 0.3s;

      @include media-tablet-x {
        top: 1.9375em;
        left: 1.375em; } }

    .tariff__description {
      margin-left: -2.125em; } }

  &__step {
    &-title {
      @include h24;
      margin: 0;

      @include media-tablet-x {
        @include h28; } }

    &-description {
      @include h16;
      font-weight: 700;

      @include media-tablet-x {
        @include h18;
        font-weight: 700; }

      span {
        color: $grey-88; } } }

  &__type {
    display: flex;
    flex-direction: column;
    gap: 1.125em; }

  &__schedule {
    display: flex;
    flex-direction: column;
    row-gap: 0.875em;
    width: 100%;

    &-item {
      &-header {
        @include fs14;
        position: relative;
        display: flex;
        height: 2.1875rem;
        column-gap: 0.25rem;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem 0.5rem 0 0;
        background-color: $red;
        color: $white;
        font-weight: 700;
        overflow: hidden;

        @include media-tablet-x {
          padding: 0.5rem 1.5rem; }

        span {
          display: none;

          @include media-tablet-x {
            display: block; } } }

      &-body {
        display: flex;
        flex-direction: column;
        gap: 0.75em;
        padding: 1em;
        border: 1px solid $grey-c0;
        border-radius: 0 0 0.5em 0.5em;

        @include media-tablet-x {
          padding: 1.5em; } }

      &-info {
        display: flex;
        justify-content: space-between; }

      &-date {
        @include fs16;
        font-weight: 800; }

      &-flag {
        @include fs11;
        font-weight: 800; }

      &-company {
        @include fs11;
        color: $red; }

      &-desc {
        @include fs14; }

      &-btn {
        margin: 0.5rem 0 0 auto;

        a {
          display: block;

          &:hover {
            svg {
              fill: $red;

              rect {
                stroke: $white; }

              path {
                fill: $white; } } } }

        svg {
          display: block; } }

      &--mald {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: $white url(@public/images/schedule/schedule-pattern-2.png) left center / 2.875rem auto;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem;
              background-size: 3.875rem auto; } } } }

      &--sey {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: $white url(@public/images/schedule/schedule-sey.svg) center / 1.875rem auto no-repeat;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem; } } } }

      &--arab-lux {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: $white url(@public/images/schedule/schedule-pattern-2.png) left center / 2.875rem auto;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem;
              background-size: 3.875rem auto; } } } }

      &--cuba {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: $white url(@public/images/schedule/schedule-cuba.jpeg) center / 2.75rem auto no-repeat;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem; } } } }

      &--bel {
        .payment__schedule-item-header {
          &::after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 4.5rem;
            height: 2.1875rem;
            background: url(@public/images/schedule/schedule-bel.svg) left center / cover no-repeat;
            border-top-left-radius: 0.5rem;

            @include media-tablet-x {
              width: 8.5rem; } } } } } } }
