.prizes {
  position: relative;
  padding-top: 3.5em;
  padding-bottom: 3.5em;
  background-color: $grey-f0;

  @include media-tablet-h {
    padding-top: 5em;
    padding-bottom: 5em; }

  &__cont {
    display: flex;
    flex-direction: column;
    row-gap: 3.5em;

    @include media-tablet-h {
      row-gap: 5em; } }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 2em;

    @include media-tablet-h {
      flex-direction: row;
      column-gap: 4.5em; } }

  &__title {
    @include reset;
    @include h36;

    @include media-tablet {
      @include h48; } }

  &__desc {
    @include fs14;
    display: flex;
    flex-direction: column;
    row-gap: 0.875rem;

    @include media-tablet-h {
      @include fs16;
      width: calc(50% - 1rem); }

    p {
      @include reset; }

    ul {
      margin: 0; } }

  &__link {
    font-weight: 600;
    color: $red;
    text-decoration: underline;

    &:hover {
      text-decoration: none; } }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 2em;

    @include media-tablet-h {
      flex-direction: row; } }

  &__group {
    border-radius: 0.75em;
    overflow: hidden;

    @include media-tablet-h {
      width: calc( 50% - 1em ); }

    &-image {
      width: 100%;
      height: 14.5625em;
      border-radius: 0.25em;
      overflow: hidden;

      @include media-tablet {
        height: 26.25em; }

      img {
        @include cover-img; } }

    &-title {
      @include h28;
      position: relative;
      padding: 1.5rem;
      border-radius: 0 0 0.75rem 0.75rem;
      background: $white;
      z-index: 101;

      @include media-tablet {
        @include h32;
        padding: 2.5rem 2rem; }

      @include media-tablet-h {
        @include h28; }

      @include media-desktop {
        @include h32;
        padding: 2.5rem; } } }

  &__item {
    position: relative;
    margin-top: -1.25em;
    padding: 2.75em 1.5em 1.5em;
    color: $white;
    border-radius: 0 0 0.75em 0.75em;

    @include media-tablet {
      padding: 3.75em 2em 2.5em; }

    @include media-desktop {
      padding: 3.75em 2.5em 2.5em; }

    &:nth-child(even) {
      background: $red;

      .prizes__item-desc {
        li, p {
          span {
            color: $dark-grey; } } } }

    &:nth-child(odd) {
      background: $dark-grey; }

    &-content {
      display: flex;
      flex-direction: column;
      row-gap: 2em; }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 1em;

      @include media-tablet {
        column-gap: 1.5em; } }

    &-title {
      @include reset;
      @include fs18;
      font-weight: 700;

      @include media-tablet {
        @include fs24; }

      @include media-desktop {
        @include h28;
        font-weight: 700; } }

    &-button {
      flex: 0 0 2.125rem;
      padding: 0;
      border: none;
      box-shadow: none;
      background: none;

      @include media-tablet {
        flex-basis: 2.9375rem; }

      img {
        display: block;
        transition: transform 0.2s ease; }

      &--open {
        img {
          transform: rotate(-180deg); } } }

    &-desc {
      display: flex;
      flex-direction: column;
      row-gap: 1.25rem;

      &--hidden {
        display: none; }

      h5 {
        @include reset;
        @include h16;

        @include media-tablet {
          @include h20; }

        @include media-desktop {
          @include h24; } }

      p {
        @include reset;
        @include fs14;

        @include media-tablet {
          @include fs20; }

        @include media-desktop {
          @include fs24; }

        span {
          color: $red;
          font-weight: 600; } }

      ul {
        @include lreset;
        @include fs14;
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;

        @include media-tablet {
          @include fs20; }

        @include media-desktop {
          @include fs24; }

        li {
          span {
            color: $red;
            font-weight: 600; } } }

      .list-circle {
        @include list-circle; } } }

  @for $i from 10 through 1 {
    .prizes__item:nth-child(#{$i}) {
      z-index: 100 - $i; } } }
