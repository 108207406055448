.stands {
  padding-top: 3.5em;
  background-color: $grey-f0;

  @include media-tablet {
    padding-top: 5em; }

  .cont {
    width: 100%;

    @include media-desktop-1600 {
      width: 100rem; } }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 1.5em; }

  &__title {
    @include h36; }

  &__preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6.25em; }

  &__filter {
    padding-bottom: 3.5em;

    &-list {
      @extend .schedule__filter-list; }

    &-item {
      margin-right: 1em;

      &:last-of-type {
        margin-right: 0; }

      label {
        display: block;
        margin-bottom: 0.5em;
        padding: 0.312em 0.555em;
        background-color: white;
        border-radius: 0.25em;
        cursor: pointer;
        user-select: none; }

      input:checked + label {
        color: white;
        background-color: $color-red; } }

    &-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1.5em;

      @include media-tablet {
        margin-top: 2.5em; } }

    &-select {
      position: relative;
      width: 100%;
      border-radius: 1.25rem;
      background-color: $white;

      @include media-tablet {
        width: calc(50% - 1.25rem); }

      @include media-desktop-1440 {
        width: calc(25% - 1.25rem); }

      &::after {
        content: "";
        width: 11px;
        height: 18px;
        position: absolute;
        right: 1.5em;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        background-image: url("https://profi.travel/uploads/workshops/projects/otm2021/img/schedule-slider-arrow.svg");
        background-repeat: no-repeat; }

      input[type="text"] {
        @include fs16;
        position: relative;
        display: block;
        width: 100%;
        padding: 0.75rem 1.25rem;
        border: none;
        border-radius: 1.25rem;
        background-color: $white;
        appearance: none;

        @include media-tablet {
          @include fs18; } }

      .netw-filter__country-reset {
        border-radius: 0 1.25rem 1.25rem 0; }

      &--direction {
        .netw-filter__country-reset {
          z-index: 90; }

        input[type="text"],
        &::after {
          z-index: 80; }

        input[type="text"] + div {
          z-index: 70; } }

      &--type {
        .netw-filter__country-reset {
          z-index: 60; }

        input[type="text"],
        &::after {
          z-index: 50; }

        input[type="text"] + div {
          z-index: 40; } }

      &--specialization {
        .netw-filter__country-reset {
          z-index: 30; }

        input[type="text"],
        &::after {
          z-index: 20; }

        input[type="text"] + div {
          z-index: 10; } } }

    &-show-text {
      @include reset;
      @include p18;
      color: $dark-grey;
      border: none;

      button {
        color: $color-red;
        font-size: 1em;
        background: $grey-f0;
        text-decoration: underline;
        border: none;
        cursor: pointer;

        &:hover {
          text-decoration: none; } } } }

  &__general {
    padding-top: 2.5em;
    padding-bottom: 5em;
    background-color: $dark-grey;

    @include media-tablet {
      padding-top: 5em; } }

  &__not-general {
    position: relative;
    padding-top: 2.5em;
    padding-bottom: 5em;
    background-color: $dark-grey;

    @include media-tablet {
      padding-top: 5em; }

    @include media-desktop {
      background-color: $red;
      overflow: hidden; }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 18.75em;
      background-color: $red;

      @include media-desktop {
        display: none; } }

    .cont {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 2em;

      @include media-tablet {
        row-gap: 4em; } }

    &-bg {
      display: none;

      @include media-desktop {
        position: absolute;
        top: 12.125em;
        right: 9.125em;
        display: block;
        width: 76.25em;
        height: 91.125em;
        background-image: url("../images/no-general-pattern.svg");
        background-repeat: no-repeat; }

      &::before {
        @include media-desktop {
          content: "";
          position: absolute;
          right: 76.2em;
          top: 0em;
          width: 100em;
          height: 591.25em;
          background-color: $dark-grey; } }

      &::after {
        @include media-desktop {
          content: "";
          position: absolute;
          top: 91.125em;
          right: 0;
          width: 76.25em;
          height: 500em;
          background-color: $dark-grey; } } } }

  &__group {
    .cont {
      display: grid;
      display: flex;
      flex-direction: column;
      row-gap: 2.5em; }

    &--hidden {
      display: none; }

    &-title {
      @include h24;
      position: relative;
      font-weight: 800;
      color: #fff;

      @include media-tablet {
        @include h36; }

      span {
        color: $color-red; } }

    &-list {
      @include media-tablet {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: row;
        column-gap: 1.875em;
        grid-row-gap: 1.875em; }

      @include media-tablet-h {
        row-gap: 2em;
        grid-template-columns: repeat(6, 0.5fr); }

      @include media-desktop {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2em; } }

    &--general {}

    &--partners {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 2.5em; }

    &--participants {
      display: flex;
      flex-direction: column;
      row-gap: 2.5em; } }

  &__slider {
    &:nth-of-type(n + 2) {
      margin-top: 2.5em;

      @include media-tablet {
        margin-top: 2em; } } }

  .swiper-pagination {
    position: relative;
    bottom: 0 !important;
    margin-top: 1.5em;

    @include media-tablet {
      display: none; } }

  .swiper-container {
    width: 100%; }

  .swiper-pagination-bullet {
    width: 0.625em;
    height: 0.625em;
    background-color: $grey-c0;
    opacity: 1;

    &-active {
      background-color: #fff; } }

  &__group--general {
    .swiper-pagination-bullet {
      background-color: #ffffff;

      &-active {
        background-color: $color-red; } } } }
